.filterWrapper {
  position: relative;

  [class~='ant-select'] {
    width: 100%;
  }

  .close {
    position: absolute;
    right: -5px;
    z-index: 1;
    top: -5px;
    display: none;
    border-radius: 50%;
    background-color: rgb(255, 77, 79);

    &.visible {
      display: block;
    }
  }
}
