@import '../../../../../styles/colors';

.productList {
  width: 100%;
}

.metadata {
  border-top: 1px solid $gray-200;
}

.pagination {
  display: flex;
  justify-content: center;
}

