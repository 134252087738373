.container {
  .card {
    min-height: 460px;
    min-width: 300px;
    flex: 1;
  }
}



@media (max-width: 460px) {
  .container {
    display: flex;
    flex-direction: column;

    .card {
      min-width: unset;
      width: 100%;
      overflow: hidden;
    }
  }
}
