@import '../../../../../styles/colors';

.header {
  width: 100%;
}

.createButton {
  margin-top: 20px;
}

.productCard {
  width: 100%;

  .valueInfo {
    margin-bottom: 20px;
  }

  .productWrapper {
    margin-right: 0;
    margin-left: 0;
    width: 100%;

    .categoriesWrapper {
      width: 100%;

      .autoCreateCategory {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
          margin-right: 10px;
        }
      }

      .categories {
        width: 100%;
      }
    }

    .priceWrapper {
      width: 100%;

      .price {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        background-color: #7ec1ff66;
        border-radius: 5px;

        .header {
          display: flex;
          justify-content: flex-end;

          .close {
            color: #eb2f96;
            cursor: pointer;
            font-weight: bold;
            transition: 0.5s;
            &:hover {
              transition: 0.5s;
              color: $blue-400;
            }
          }
        }
      }
    }

    .button {
      float: left;
    }
  }
}


