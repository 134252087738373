@import '../../../styles/colors';

.backButton {
  margin-right: 10px;
}

.btn {
  margin: 0px 8px;
  color: $orange-600
}
