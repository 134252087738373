.container {
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .action {
    border: 1px solid #1890ff;
    padding: 5px 10px;

    svg {
      margin-right: 7px;
      color: #1890ff;
    }
  }
}
