.valueRow {
  width: 100%;
  [class~=ant-collapse-header] {
    align-items: center;
  }
  [class~=ant-collapse-header-text] {
    width: 100%;
  }

  [class~=ant-form-item-label] {
    min-width: 100px;
    text-align: left;
  }
  .valueContainer {
    width: 100%;
    height: 100%;
  }
}

.valueHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addValueButton {
  margin-top: 20px;
}
