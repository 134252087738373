.general {
  height: 100%;
}

.save {
  margin-top: 20px;;
}

.form {
  [class~=ant-form-item-label] {
    min-width: 100px;
    text-align: left;
  }
}

.regionHeader {
  display: flex;
  justify-content: space-between;
}

.regionButton {
  margin-top: 20px;
}

[class~='ant-collapse-header-text'] {
  width: 100%;
}
