.layoutContainer {
  display: flex;
  height: 100%;
}

.sidebar {
  height: 100%;
  background: #001529;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;

  .toggleButton {
    margin-left: 16px;
    margin-bottom: 16px;
  }
  .menu {
    width: 256px;
    &.collapsed {
      width: 80px;
    }
  }
}


@media (max-width: 460px) {
  .sidebar {
    &.notSolid {
      position: absolute;
      z-index: 1;
    }
  }

}
