.keyValue {
  display: flex;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: bolder;
  }
  .value {
    margin-left: 5px;
  }
}
