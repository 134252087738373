@import 'src/styles/colors';
.purchaseProductList {
  width: 100%;
}

.metadata {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
