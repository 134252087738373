.avatar {
  display: flex;
  align-items: center;
  .avatarTitle {
    font-size: 16px;
    font-weight: bolder;
    margin-left: 5px;
  }

  .googleIcon {
    margin-left: 10px;
  }
}
