.avatar {
  display: flex;
  align-items: center;
  .avatarTitle {
    font-size: 16px;
    font-weight: bolder;
    margin-left: 5px;
  }
}

.profit {
  [class*=value] {
    color: #7cb305;
  }
}
