@import 'styles/colors';

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.listWrapper {
  height: 500px;
}

.list {
  transition: 0.5s;
  position: relative;

  &:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 0.5s;
  }

  .edit {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}


.description {
  display: flex;
  justify-content: space-between;

  .store {
    font-size: 19px;
    color: rgb(0 0 0 / 85%);
    text-transform: capitalize;

    .name {
      margin-left: 10px;
    }
  }
}

.tag {
  color: white;
  width: fit-content;
  margin-bottom: 5px;
  background-color: #55acee;
}

.title{
  margin-left: 10px;
  color: $gray-300;
}
