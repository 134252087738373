.item {
  text-align: center;
  padding: 45px 0;
  width: 100%;
  font-weight: bolder;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
}

.badge {
  width: 100%;
}
