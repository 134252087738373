.searchWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  border: 1px solid #d9d9d9;

  input {
    border: unset;
    outline: unset;
    box-shadow: unset;
  }

  .searchInput {
    &::placeholder {
      color: gray;
    }
  }

  .searchIcon {
    margin-left: 10px;
    cursor: pointer;
    margin-right: 10px;
  }

  .cancel {
    position: absolute;
    right: -8px;
    top: -8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgb(255, 77, 79);

    g {
      fill: white;
    }
  }
}
