@import '../../../styles/colors';

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.list {
  display: flex;
  transition: 0.5s;

  &:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 0.5s;
  }

  .referenceCode {
    font-weight: 600;
    width: fit-content;
    padding: 5px;
    border-radius: 0px 0px 10px 0px;
    color: white;
  }

  .price {
    font-size: 22px;
  }

  .amount {
    font-size: 18px;
    color: $gray-600;
  }

  .rightContainer {
    font-weight: 600;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .date {
      color: $gray-300;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

