.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .search {
    width: 250px;
    margin-left: 10px;
  }

  .filter {
    width: 250px;
  }
}

.action {
  border: 1px solid #1890ff;
  padding: 5px 10px;
  svg {
    margin-right: 7px;
    color: #1890ff;
  }
}

